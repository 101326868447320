import NextLink from 'next/link'
import { Box, BoxProps, Image, useBreakpointValue, useTheme, LinkBox } from '@chakra-ui/react'
import { fontFamilies } from 'composable/chakra/figma-tokens'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'
import { card_DEFAULT_CSS_DESKTOP, card_DEFAULT_CSS_MOBILE } from './RichText-Utils/card-styles'
import { BaseProps, ObjectFit } from './types'
import { extractPath } from '../../helpers/utils/extract-url'
import { imageOptimization } from '../general/components/image-optimization'

export interface CoverCardProps extends BaseProps {
  theme?: CoverCardTheme
  contentAlignment?: CoverCardTextAlign
  root?: Omit<BoxProps, 'children'>
  image?: {
    src?: string
    alt?: string
    height?: number
    objectFit?: ObjectFit
  }
  overlayImage?: {
    src?: string
    alt?: string
    height?: string | null
  }
  eyebrow?: BoxProps
  title?: {
    children?: string
    font?: {
      size?: string
      family?: string
    }
  }
  description?: BoxProps
  href?: string
  contentPositioning?: string
  containerMarginBottom?: string
  containerMarginTop?: string
  containerSize?: string
  overlayBackground?: string
  displayOpacityOverlay?: boolean
  onClick?: () => void
  analyticsTrackingData?: string
  relativeIndex?: number
}

export type CoverCardTheme = 'light' | 'dark'
export type CoverCardTextAlign = 'left' | 'center' | 'right'

export const CoverCard = ({
  theme = 'dark',
  contentAlignment = 'left',
  root,
  image,
  overlayImage,
  eyebrow,
  title,
  description,
  href,
  contentPositioning,
  containerMarginBottom,
  containerMarginTop,
  containerSize,
  overlayBackground = overlayBackgroundValue[theme],
  displayOpacityOverlay = true,
  onClick,
  analyticsTrackingData,
  relativeIndex,
  isMobileDevice,
}: CoverCardProps) => {
  const getTheme = useTheme()
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const _theme = isMobile ? 'light' : 'dark'
  const getContainerSize = containerSize === 'full' ? '100%' : getTheme.breakpoints[containerSize]

  const minHeight: BoxProps['minHeight'] = root?.minHeight ?? ['309px']

  const { font: titleFont } = title ?? {}

  const headingFontFamily = titleFont?.family ?? 'libre-franklin'
  const headingFontSize = titleFont?.size ?? '400'

  const headingFontStyleSuffix =
    headingFontFamily === 'libre-franklin' ? `-${headingFontSize}` : `-secondary-${headingFontSize}`

  const headingFontStyleToken = {
    desktop: `heading-desktop${headingFontStyleSuffix}`,
    mobile: `heading-mobile${headingFontStyleSuffix}`,
  }

  let titleFontFamily

  if (titleFont?.family === 'antonio' || titleFont?.family === 'libre-franklin') {
    titleFontFamily = titleFont?.family === 'antonio' ? fontFamilies.secondary : fontFamilies.primary
  } else {
    titleFontFamily = titleFont?.family || fontFamilies.primary
  }

  const analyticsLocationIdentifier = extractPath(href ?? '')
  const dataPromotionName =
    analyticsTrackingData && `${analyticsTrackingData} | ${relativeIndex} | ${analyticsLocationIdentifier}`

  return (
    <>
      {href ? (
        <LinkBox
          as={NextLink}
          onClick={() => promoClickUtagLinkEvent(dataPromotionName)}
          href={href}
          _hover={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
        >
          <Box
            minHeight={minHeight}
            position="relative"
            display="flex"
            flexDirection="column"
            mt={!!containerMarginTop ? containerMarginTop : 0}
            mb={!!containerMarginBottom ? containerMarginBottom : 0}
            px={root?.px || isMobile ? 4 : 5}
            py={root?.py || isMobile ? 0 : 10}
            maxWidth={getContainerSize}
            onClick={onClick}
            data-promotion-name={dataPromotionName}
            {...root}
          >
            <Box
              {...(isMobile
                ? {
                    position: 'relative',
                  }
                : {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  })}
            >
              {overlayImage?.src && isMobile && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src={imageOptimization(overlayImage?.src)}
                    alt={overlayImage.alt}
                    height={overlayImage.height || '4rem'}
                  />
                </Box>
              )}
              {image?.src && (
                <Image
                  src={imageOptimization(image?.src)}
                  alt={image?.alt}
                  width="100%"
                  height="100%"
                  style={{ objectFit: image?.objectFit ?? 'cover' }}
                />
              )}
              {!isMobile && displayOpacityOverlay && (
                <Box position="absolute" top="0" left="0" width="100%" height="100%" background={overlayBackground} />
              )}
            </Box>

            <Box
              display="flex"
              position="relative"
              flexDirection="column"
              alignItems={contentAlignment ?? 'left'}
              justifyContent={contentPositioning ?? 'flex-start'}
              textAlign={getTextAlignment(contentAlignment)}
              flex={1}
            >
              {overlayImage?.src && !isMobile && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={contentAlignment}
                  justifyContent={contentPositioning}
                  py={{ base: 5 }}
                  px={{ base: isMobile ? 0 : 4 }}
                >
                  <Image
                    src={imageOptimization(overlayImage?.src)}
                    alt={overlayImage.alt}
                    height={overlayImage.height || '4rem'}
                  />
                </Box>
              )}

              <Box px={{ base: isMobile ? 0 : 4 }} width="100%">
                {eyebrow?.children && (
                  <Box
                    textStyle={['eyebrow-50', null, 'eyebrow-75']}
                    layerStyle={`${_theme}-text`}
                    maxWidth={['unset', null, 'unset']}
                    pt={{ base: isMobile ? 4 : 0 }}
                    paddingY={1.5}
                    {...eyebrow}
                  />
                )}
                {title?.children && (
                  <Box
                    textStyle={[headingFontStyleToken.mobile, null, null, headingFontStyleToken.desktop]}
                    fontFamily={titleFontFamily}
                    layerStyle={`${_theme}-text`}
                    paddingY={1.5}
                    sx={{
                      textDecoration: 'none',
                      color: 'rgb(21, 25, 28)',
                      '&:hover, &:focus': {
                        textDecoration: 'none',
                        color: 'rgb(21, 25, 28)',
                      },
                    }}
                  >
                    {title.children}
                  </Box>
                )}
                {description?.children && (
                  <Box
                    __css={{
                      ...(isMobile ? card_DEFAULT_CSS_MOBILE : card_DEFAULT_CSS_DESKTOP),
                      'h1, h2, h3, h4, h5, h6': {
                        fontFamily: titleFontFamily,
                      },
                    }}
                    textStyle={['callouts-blockquote-75-Default', null, 'body-100']}
                    paddingY={1.5}
                    layerStyle={`${_theme}-text`}
                    {...description}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </LinkBox>
      ) : (
        <Box
          minHeight={minHeight}
          position="relative"
          display="flex"
          flexDirection="column"
          mt={!!containerMarginTop ? containerMarginTop : 0}
          mb={!!containerMarginBottom ? containerMarginBottom : 0}
          px={root?.px || isMobile ? 4 : 5}
          py={root?.py || isMobile ? 0 : 10}
          maxWidth={getContainerSize}
          onClick={onClick}
          {...root}
        >
          <Box
            {...(isMobile
              ? {
                  position: 'relative',
                }
              : {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                })}
          >
            {overlayImage?.src && isMobile && (
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Image src={overlayImage.src} alt={overlayImage.alt} height={overlayImage.height || '4rem'} />
              </Box>
            )}
            {image?.src && (
              <Image
                src={image?.src ?? ''}
                alt={image?.alt ?? ''}
                width="100%"
                height="100%"
                style={{ objectFit: image?.objectFit ?? 'cover' }}
              />
            )}
            {!isMobile && displayOpacityOverlay && (
              <Box position="absolute" top="0" left="0" width="100%" height="100%" background={overlayBackground} />
            )}
          </Box>

          <Box
            display="flex"
            position="relative"
            flexDirection="column"
            alignItems={contentAlignment ?? 'left'}
            justifyContent={contentPositioning ?? 'flex-start'}
            textAlign={getTextAlignment(contentAlignment)}
            flex={1}
          >
            {overlayImage?.src && !isMobile && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems={contentAlignment}
                justifyContent={contentPositioning}
                py={{ base: 5 }}
                px={{ base: isMobile ? 0 : 4 }}
              >
                <Image src={overlayImage.src} alt={overlayImage.alt} height={overlayImage.height || '4rem'} />
              </Box>
            )}

            <Box px={{ base: isMobile ? 0 : 4 }} width="100%">
              {eyebrow?.children && (
                <Box
                  textStyle={['eyebrow-50', null, 'eyebrow-75']}
                  layerStyle={`${_theme}-text`}
                  maxWidth={['unset', null, 'unset']}
                  pt={{ base: isMobile ? 4 : 0 }}
                  paddingY={1.5}
                  {...eyebrow}
                />
              )}
              {title?.children && (
                <Box
                  textStyle={[headingFontStyleToken.mobile, null, null, headingFontStyleToken.desktop]}
                  fontFamily={titleFontFamily}
                  layerStyle={`${_theme}-text`}
                  paddingY={1.5}
                >
                  {title.children}
                </Box>
              )}
              {description?.children && (
                <Box
                  __css={{
                    ...(isMobile ? card_DEFAULT_CSS_MOBILE : card_DEFAULT_CSS_DESKTOP),
                    'h1, h2, h3, h4, h5, h6': {
                      fontFamily: titleFontFamily,
                    },
                  }}
                  textStyle={['callouts-blockquote-75-Default', null, 'body-100']}
                  paddingY={1.5}
                  layerStyle={`${_theme}-text`}
                  {...description}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

const overlayBackgroundValue: Record<CoverCardTheme, string> = {
  dark: 'rgba(0, 0, 0, 0.5)',
  light: 'rgba(255, 255, 255, 0.5)',
}

const getTextAlignment = (contentAlignment: string) => {
  if (contentAlignment === 'center') {
    return 'center'
  }

  if (contentAlignment === 'flex-end') {
    return 'right'
  }

  return 'left'
}
